import { render, staticRenderFns } from "./EpCollapse.vue?vue&type=template&id=23b3ba5d&scoped=true"
import script from "./EpCollapse.vue?vue&type=script&lang=ts"
export * from "./EpCollapse.vue?vue&type=script&lang=ts"
import style0 from "./EpCollapse.vue?vue&type=style&index=0&id=23b3ba5d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b3ba5d",
  null
  
)

export default component.exports