import { render, staticRenderFns } from "./EpAmmatillinenRow.vue?vue&type=template&id=05b97f1a&scoped=true"
import script from "./EpAmmatillinenRow.vue?vue&type=script&lang=ts"
export * from "./EpAmmatillinenRow.vue?vue&type=script&lang=ts"
import style0 from "./EpAmmatillinenRow.vue?vue&type=style&index=0&id=05b97f1a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b97f1a",
  null
  
)

export default component.exports