import { render, staticRenderFns } from "./EpTiedostoInput.vue?vue&type=template&id=25863f4b&scoped=true"
import script from "./EpTiedostoInput.vue?vue&type=script&lang=ts"
export * from "./EpTiedostoInput.vue?vue&type=script&lang=ts"
import style0 from "./EpTiedostoInput.vue?vue&type=style&index=0&id=25863f4b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25863f4b",
  null
  
)

export default component.exports