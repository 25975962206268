import { render, staticRenderFns } from "./RoutePerusteTiedot.vue?vue&type=template&id=28897f20&scoped=true"
import script from "./RoutePerusteTiedot.vue?vue&type=script&lang=ts"
export * from "./RoutePerusteTiedot.vue?vue&type=script&lang=ts"
import style0 from "./RoutePerusteTiedot.vue?vue&type=style&index=0&id=28897f20&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28897f20",
  null
  
)

export default component.exports