import { render, staticRenderFns } from "./EpFooter.vue?vue&type=template&id=b2c1f93a&scoped=true"
import script from "./EpFooter.vue?vue&type=script&lang=ts"
export * from "./EpFooter.vue?vue&type=script&lang=ts"
import style0 from "./EpFooter.vue?vue&type=style&index=0&id=b2c1f93a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2c1f93a",
  null
  
)

export default component.exports