import { render, staticRenderFns } from "./EpContentViewer.vue?vue&type=template&id=97e1386c&scoped=true"
import script from "./EpContentViewer.vue?vue&type=script&lang=ts"
export * from "./EpContentViewer.vue?vue&type=script&lang=ts"
import style0 from "./EpContentViewer.vue?vue&type=style&index=0&id=97e1386c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e1386c",
  null
  
)

export default component.exports